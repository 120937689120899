@import "_setting";
@import "_mixin";

// opacity: 0
.mv__text-title,
.mv__text-message,
.sandc-title,
.sandc-message,
.sandc__diagram ,
.flow-title,
.flow__01,
.flow__steps,
.sandc__timing,
.case-title,
.company-title,
.contant-title,
.company-title,
.company__inner/*,
#contact .container*/
 {
  opacity: 0;
}


.js-slide-left {
  animation-name: left-fedein;
  animation-duration: 0.4s;
  animation-timing-function: ease-in;
  animation-fill-mode: forwards;
  position: relative;
}

.js-slide-up {
  animation-name: slide-up;
  animation-duration: 0.4s;
  animation-timing-function: ease-in;
  animation-fill-mode: forwards;
  position: relative;
}

.js-fadein {
  animation-name: fadein;
  animation-duration: 0.4s;
  animation-timing-function: ease-in;
  animation-fill-mode: forwards;
}

.js-animation-delay03 {
  animation-delay: .3s;
}
.js-animation-delay05 {
  animation-delay: .5s;
}
.js-animation-delay08 {
  animation-delay: .8s;
}
.js-animation-delay1 {
  animation-delay: 1s;
}
.js-animation-delay15 {
  animation-delay: 1.5s;
}
.js-animation-delay2 {
  animation-delay: 2;
}

// animation

@keyframes left-fedein {
  0% {
    left: -60px;
    opacity: 0;
  }
  100% {
    left: 0;
    opacity: 1;
  }
}

@keyframes slide-up {
  0% {
    top: 20px;
    opacity: 0;
  }
  100% {
    top: 0;
    opacity: 1;
  }
}

@keyframes fadein {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

// other settings
.mv__slider {
  &::after {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    z-index: 2;
    background-color: #fff;
    transition: all 1s ease-in-out;
  }
  @include tabletP {
    &::after {
      content: none;
    }
  }
}

.js-cover {
  position: relative;
  &::after {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    z-index: 2;
    background-color: #fff;
    transition: all 1s ease-in-out;
  }
  &.is-show {
    &::after {
      width: 0;
    }
  }
}
